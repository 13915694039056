import { Utility } from 'inter-site-components'
import { useEffect, useState } from 'react'
import { WIDTH_SM } from '../../utils/breakpoints'

const DEFAULT_DEBOUNCE = 100

/**
 * Custom hook that gets the window width
 * arg throttleValue - value for debounce
 * @return windowWidth - the current width from the page
 */
export default function useWidth (debounceValue) {
  const [ windowWidth, setWindowWidth ] = useState(WIDTH_SM)

  // if no value was defined to limit our function to be fired infinitely,
  // then we must ensure that at least a default value is triggered (defaultThrottle)
  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const updateWidthValues = Utility.debounce(() => {
      setWindowWidth(window.innerWidth)
    }, (!debounceValue) ? DEFAULT_DEBOUNCE : debounceValue)

    window && window.addEventListener('resize', updateWidthValues, false)
    return () => { window && window.removeEventListener('resize', updateWidthValues, false) }
  }, [])

  return windowWidth
}
